<template>
  <div class="day">
    <h1>
      <span class="day-date">{{ psaltirKafismas.name }}</span>
      <span v-if="psaltirKafismas.desc">
        <br>
        <span class="day-name">{{ psaltirKafismas.desc }}</span>
      </span>
    </h1>
    <template v-for="i in psaltirKafismas.kafismas">
      <router-link class="day-back day-link" :to="{name:'kafisma', params: { block_id: psaltirKafismas.id, kafisma_id: i.id }}" v-if="!i.desc" :key="'kaf-' + i.id">
        {{ i.name }}
      </router-link>
      <a v-else-if="i.desc && i.desc.startsWith('https://')" :href="i.desc" target="_blank" class="day-link"  :key="'kaf-' + i.id">
        {{ i.name }}
      </a>
      <router-link class="day-back day-link" :to="{name:'kafisma', params: { block_id: psaltirKafismas.id, kafisma_id: i.id }}" v-else  :key="'kaf-' + i.id">
        <div class="day-name">{{ i.name }}</div>
        <div class="day-desc">{{ i.desc }}</div>
      </router-link>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "Psaltir",
  created() {
    document.title = "Помощник чтеца";
    this.fetch()
  },
  watch: {
    $route: 'fetch'
  },
  computed: {
    ...mapState({
      psaltirKafismas: state => state.psaltirKafismas
    })
  },
  methods: {
    fetch() {
      this.$store.dispatch('getPsaltirKafismas', this.$route.params);
    }
  },
}
</script>

<style scoped lang="less">
@import "../assets/vars";

.day {
  h1 {
    margin: 0 0 1rem 0;
    padding: 0;
    text-align: center;
    font-size: 1.2rem;
    color: @color-h;

    .day-date {
      font-size: 1.5rem;
    }
  }

  .day-link {
    display: block;
    padding: .5rem;
    text-decoration: none;
    margin-bottom: 1rem;
    background-color: @color-h;
    color: @color-l;
    text-align: center;

    div.day-name{
      padding-bottom: .2rem;
      margin-bottom: .2rem;
      border-bottom: 1px solid @color-l;
    }

    div.day-desc{

    }
  }

  .day-lit {
    display: block;
    padding: .5rem;
    text-decoration: none;
    margin: 3rem 0 1rem 0;
    background-color: @color-h;
    color: @color-l;
    text-align: center;
  }

  .day-back {
    text-align: center;
    text-decoration: none;
  }
}

</style>
